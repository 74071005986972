import React from "react"
import { graphql } from "gatsby"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Pagination from "rc-pagination"
import localeInfo from 'rc-pagination/lib/locale/en_US';

import breakpoints from "../utils/breakpoints"
import Screenshot from "../components/screenshot"

import styled from "@emotion/styled"

const Article = styled.article`
  position: relative;
  width: 100%;
`

const H1 = styled.h1`
  // position: absolute;
  // background: rgba(255, 255, 255, 0.7);
  // text-indent: 1rem;
  // top: 0;
  // width: 100%;
  // height: 5rem;
  // 
  // line-height: 5rem;
  // padding: 0 1.2rem;
  // margin: 1rem 0;
`

const Wrapper = styled.ul`
  margin: 0 -1rem;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  // li:nth-child(2) {
  //   margin-right: 120px;
  // }

  // li:nth-child(2) {
  //   margin-right: 120px;
  // }
`

const PagnationWrapper = styled.nav`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0.7rem 0 1.5rem 0;

  .rc-pagination {

    > li {
      font-size: 1.2rem;
      line-height: 1.9rem;
      height: 2.1rem;
      margin-right: 0;
      border-radius: 0;
      border-left: 0;

      a {
        line-height: 2.2rem;
        padding: 0 0.75rem;
        display: block;
        font-family:  'SquareSerifBook';
      }
    }

    li:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    li:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .rc-pagination-item {

    display: none;
    
    ${breakpoints[0]} {
      display: block;
    }

    &:hover {
      border-color: #d9d9d9;
      background-color: #efefef;
      a {
        // color: ${props => props.theme.colors.primary};
        color: #666;
        text-decoration: none;
      }
    }
  }

  .rc-pagination-item-active {
    background: ${props => props.theme.colors.primary};
    border-color: #d9d9d9;

    &:hover {
      background: ${props => props.theme.colors.primary};
      border-color: #d9d9d9;
      a {
        color: white
      }
    }
  }

  .rc-pagination-prev, .rc-pagination-next {
    background-color: #fff;

    a{
      color: #666;
    }

    &:hover {
      border-color: #d9d9d9;
      background-color: #efefef;
    }
  }

  .rc-pagination-disabled {
    opacity: 0.7;

    &:hover {
      background: white;
    }
  }

  @media (max-width: 575px) {
    .rc-pagination {
      li.rc-pagination-prev, 
      li.rc-pagination-next {
        background-color: ${props => props.theme.colors.primary};
        
        height: auto;
        border-radius: 5px;
        margin 0 1rem;

        a {
          display: block;
          padding: 2rem;
          font-size: 6rem;
          line-height: 1rem;
          color: white;
          text-decoration: none;
          overflow: hidden;
          outline: none;

          &:after {
            margin-top: -0.75rem;
          }
        }
      }
    }
  }
`

export default class ProjectList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      current: 0,
    }
  }

  onChange = page => {
    if(page === 1) return navigate('/')
    navigate("/projects/" + page)
  }

  render() {
    const nodes = this.props.data.allSitePage.nodes;
    const {currentPage, limit, numPages} = this.props.pageContext;

    return (
      <Layout>
        <SEO title="Projects" />
        <Article>
          <H1>Projects</H1>
          <Wrapper>
            {nodes.map(element => {
              return (
                <Screenshot
                  key={element.context.id}
                  path={element.context.hero}
                  link={element.path}
                  fluid={element.context.fluidImage}
                />
              )
            })}
            <PagnationWrapper>
              <Pagination
                onChange={this.onChange}
                total={numPages*limit}
                defaultPageSize={limit}
                current={currentPage}
                locale={localeInfo}
              />
            </PagnationWrapper>
          </Wrapper>
        </Article>
      </Layout>
    )
  }
}

export const query = graphql`
  query blahx($skip: Int!, $limit: Int!) {
    allSitePage(
      filter: {
        componentChunkName: { eq: "component---src-templates-project-js" }
      }
      limit: $limit
      skip: $skip
      sort: {fields:[context___weight, context___created_at], order: [ASC, DESC]}
    ) {
      nodes {
        path
        context {
          id
          hero
          fluidImage {
            src
            aspectRatio
            base64
            density
            originalImg
            originalName
            presentationHeight
            presentationWidth
            sizes
            srcSet
            srcSetType
          }
        }
      }
    }
  }
`
